import r from '@hat-open/renderer';
import * as u from '@hat-open/util';
export function main() {
    const result = r.get('result');
    const selected = r.get('selected');
    if (!result || !selected.panel)
        return null;
    const panel = result.params.panels[selected.panel];
    const used = u.filter(i => i.panel == selected.panel, result.used);
    const unused = u.filter(i => i.panel == selected.panel, result.unused);
    const fontSize = String(Math.max(panel.height, panel.width) * 0.02 *
        u.strictParseFloat(r.get('svg', 'font_size')));
    const showNames = r.get('svg', 'show_names');
    const showDimensions = r.get('svg', 'show_dimensions');
    const cutColor = r.get('svg', 'cut_color');
    const itemColor = r.get('svg', 'item_color');
    const selectedItemColor = r.get('svg', 'selected_color');
    const unusedColor = r.get('svg', 'unused_color');
    return ['svg', {
            attrs: {
                width: '100%',
                height: '100%',
                viewBox: [0, 0, panel.width, panel.height].join(' '),
                preserveAspectRatio: 'xMidYMid'
            }
        },
        ['rect', {
                attrs: {
                    x: '0',
                    y: '0',
                    width: String(panel.width),
                    height: String(panel.height),
                    'stroke-width': '0',
                    fill: cutColor
                }
            }
        ],
        used.map(used => {
            const item = result.params.items[used.item];
            const width = (used.rotate ? item.height : item.width);
            const height = (used.rotate ? item.width : item.height);
            return ['rect', {
                    props: {
                        style: 'cursor: pointer'
                    },
                    attrs: {
                        x: String(used.x),
                        y: String(used.y),
                        width: String(width),
                        height: String(height),
                        'stroke-width': '0',
                        fill: (used.item == selected.item ? selectedItemColor : itemColor)
                    },
                    on: {
                        click: () => r.set(['selected', 'item'], used.item)
                    }
                }
            ];
        }),
        unused.map(unused => {
            return ['rect', {
                    attrs: {
                        x: String(unused.x),
                        y: String(unused.y),
                        width: String(unused.width),
                        height: String(unused.height),
                        'stroke-width': '0',
                        fill: unusedColor
                    }
                }
            ];
        }),
        used.map(used => {
            const item = result.params.items[used.item];
            const width = (used.rotate ? item.height : item.width);
            const height = (used.rotate ? item.width : item.height);
            const click = () => r.set(['selected', 'item'], used.item);
            return [
                (!showNames ? [] : ['text', {
                        props: {
                            style: 'cursor: pointer'
                        },
                        attrs: {
                            x: String(used.x + width / 2),
                            y: String(used.y + height / 2),
                            'alignment-baseline': 'middle',
                            'text-anchor': 'middle',
                            'font-size': fontSize
                        },
                        on: {
                            click: click
                        }
                    },
                    used.item + (used.rotate ? ' \u293E' : '')
                ]),
                (!showDimensions ? [] : ['text', {
                        props: {
                            style: 'cursor: pointer'
                        },
                        attrs: {
                            x: String(used.x + width / 2),
                            y: String(used.y + height),
                            'alignment-baseline': 'baseline',
                            'text-anchor': 'middle',
                            'font-size': fontSize
                        },
                        on: {
                            click: click
                        }
                    },
                    String(width)
                ]),
                (!showDimensions ? [] : ['text', {
                        props: {
                            style: 'cursor: pointer'
                        },
                        attrs: {
                            x: String(used.x + width),
                            y: String(used.y + height / 2),
                            'transform': `rotate(-90, ${used.x + width}, ${used.y + height / 2})`,
                            'alignment-baseline': 'baseline',
                            'text-anchor': 'middle',
                            'font-size': fontSize
                        },
                        on: {
                            click: click
                        }
                    },
                    String(height)
                ])
            ];
        })
    ];
}
