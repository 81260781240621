// @ts-ignore
import Papa from 'papaparse';
export async function decode(blob, header) {
    const data = await new Promise(resolve => {
        Papa.parse(blob, {
            header: true,
            complete: (result) => resolve(result.data)
        });
    });
    const result = [];
    for (const i of data) {
        let element = {};
        for (const [k, v] of Object.entries(header)) {
            if (!(k in i)) {
                element = null;
                break;
            }
            element[k] = v(i[k]);
        }
        if (element)
            result.push(element);
    }
    return result;
}
export function encode(data) {
    const csvData = Papa.unparse(data);
    return new Blob([csvData], { type: 'text/csv' });
}
