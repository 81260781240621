import * as u from '@hat-open/util';
import * as params from './params';
import * as result from './result';
import * as svg from './svg';
export function main() {
    return ['div.main',
        ['div.left-panel', params.main()],
        leftPanelResizer(),
        ['div.center-panel', svg.main() ?? []],
        rightPanelResizer(),
        ['div.right-panel', result.main()]
    ];
}
function leftPanelResizer() {
    return ['div.panel-resizer', {
            on: {
                mousedown: u.draggerMouseDownHandler(evt => {
                    const panel = evt.target.parentNode?.querySelector('.left-panel');
                    if (panel == null)
                        return () => { }; // eslint-disable-line
                    const width = panel.clientWidth;
                    return (_, dx) => {
                        panel.style.width = `${width + dx}px`;
                    };
                })
            }
        }];
}
function rightPanelResizer() {
    return ['div.panel-resizer', {
            on: {
                mousedown: u.draggerMouseDownHandler(evt => {
                    const panel = evt.target.parentNode?.querySelector('.right-panel');
                    if (panel == null)
                        return () => { }; // eslint-disable-line
                    const width = panel.clientWidth;
                    return (_, dx) => {
                        panel.style.width = `${width - dx}px`;
                    };
                })
            }
        }];
}
